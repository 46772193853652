.News__head {
  margin-top: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.News__head-title {
  width: 996px;
  height: 131px;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 86px;
  line-height: 131px;
  text-align: center;

  /* Text colour */

  color: var(--black);
}
.News__head-body {
  margin-top: 1.5rem;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  width: 567px;
  text-align: center;

  /* Text/var(--gray) */

  color: var(--gray);
}
.News__searchbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

::placeholder {
  font-family: "General Sans";
  color: #8a8b9f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 200% */
}

.News__searchbar > input {
  background: var(--white);
  border-radius: 4px;
  font-family: "General Sans";
  width: 783px;
  border: none;
  padding: 30px 529px 32px 76px;
  outline: none;
  background-image: url("../Home/Images/search-normal.svg");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: 34px 20px;
}
.News__grid-container {
  margin-top: 6.2rem;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  gap: 65px;
  margin-bottom: 9rem;
  padding-left: 10rem;
}
.News__grid-item1 {
  grid-column-start: 1;
  grid-column-end: 4;
  /* background: #e5eaef; */
  /* background: red; */
  border-radius: 4px;
  height: 333px;
  width: 1040px;
  display: flex;
  flex-direction: row;
}
.News__grid-item1-image {
  width: 50%;
}
.News__grid-item1-content {
  background-color: var(--light-green);
  gap: 1rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
}
.News__grid-item1-content-head {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  width: 390px;
  color: var(--gray);
}
.News__grid-item1-content-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  width: 422px;
  /* Text/var(--gray) */

  color: var(--gray);
}
.News__grid-item1-content-profile {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.News__grid-item1-content-profile-img {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  /* Grey 2 */
  background: #e5eaef;
}

.News__grid-item1-content-profile-name {
  color: #333;
  font-family: "General Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.news__pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 27rem;
}

.newss__container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 778px) {
  .News__head {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .News__head-title {
    width: 440px;
    height: 54px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;

    /* Text colour */

    color: var(--black);
  }

  .News__head-body {
    margin-top: 1.5rem;
    width: 327px;
    height: 71px;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* or 200% */

    text-align: center;

    /* Text/var(--gray) */

    color: var(--gray);
  }
  .News__searchbar input {
    background: var(--white);
    border-radius: 30px;
    width: 394px;
    height: 72px;
    padding: 2rem;
    border: none;
    font-family: "General Sans";
  }
  .News__grid-container {
    margin-top: 6.2rem;
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    gap: 65px;
    margin-bottom: 9rem;
    align-items: center;
    padding-left: 0rem;
  }

  .News__grid-item1 {
    grid-column-start: 1;
    grid-column-end: 1;
    background: #e5eaef;
    border-radius: 4px;
    width: 396px;
    height: 624px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .News__grid-item1-image {
    width: 100%;

    height: 340px;
  }
  .News__grid-item1-content {
    background-color: var(--light-green);
    gap: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
  }

  .News__grid-item1-content-head {
    width: 394px;
    height: 66px;
    padding-left: 0.5rem;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;

    /* Text/var(--gray) */

    color: var(--gray);
  }
  .News__grid-item1-content-body {
    width: 396px;
    height: 128px;
    padding-left: 0.5rem;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */

    /* Text/var(--gray) */

    color: var(--gray);
  }

  .newss__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
