.aboutUs__hero {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutUs__head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: var(--light-gray);
  margin-bottom: 3rem;
}

.aboutUs__title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  text-align: center;
  color: var(--black);
  margin-bottom: 9rem;
}
.aboutUs__body {
  margin-bottom: 26.3rem;
}
.aboutUs__body-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10rem;
  margin-bottom: 4rem;
}

.aboutUs__body-1 img {
  width: 399px;
  height: 442px;
}

.aboutUs__body-1-text {
  width: 410px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aboutUs__body-1-text h2 {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: var(--black);
}

.aboutUs__body-1-text p {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  letter-spacing: -0.04em;

  /* Text colour */

  color: var(--black);
}

@media screen and (max-width: 778px) {
  .aboutUs__title {
    width: 185px;
    height: 54px;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;

    color: var(--black);
    margin-bottom: 9rem;
  }
  .column__reverse {
    display: flex;
    flex-direction: column-reverse;
    gap: 2.5rem;
    margin-bottom: 4rem;

    justify-content: center;
  }
  .column {
    gap: 2.5rem;
    display: flex;
    flex-direction: column;
  }

  .aboutUs__body-1 img {
    width: 356px;
    height: 323px;
  }

  .aboutUs__body {
    margin-bottom: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .aboutUs__body-1-text h2 {
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    color: var(--black);
    width: 188px;
    height: 48px;
  }

  .aboutUs__body-1-text p {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    width: 386px;
    height: 48px;
    letter-spacing: -0.04em;

    /* Text colour */

    color: var(--black);
  }

  .aboutUs__body-1-text {
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
