.FAQ {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  margin-bottom: 20rem;
}
.Accordion {
  margin-top: 4rem;
  /* height: 545px; */
  width: 957px;
  border-bottom: 0.5px solid var(--black);
  margin-bottom: 4rem;
}
.Title {
  /* padding: 2rem 0rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.Title > h2 {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}

.Content {
  max-height: 0;
  overflow: hidden;
  margin: 1rem 0rem;
  font-family: "General Sans";

  /* transition: all 0.5s cubic-bezier(0, 1, 0, 1); */
}
.Content.show {
  height: auto;
  max-height: 9999px;
  /* transition: all 0.5s cubic-bezier(1, 0, 1, 0); */
  line-height: 1.5rem;
  font-family: "General Sans";
}

.Faqs__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
}
.Faqs__head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;

  /* Gray / gray-7 */

  color: var(--light-gray);
  margin-bottom: 3rem;
}
.Faqs__title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  text-align: center;

  width: 777px;

  color: var(--black);
  margin-bottom: 5rem;
}

.Item {
  padding: 3rem 3rem 1rem 3rem;
  border-top: 0.5px solid var(--black);
  /* width: 957px; */
  /* height: 109px; */
  /* background: blue; */
}

@media screen and (max-width: 778px) {
  .Faqs__title {
    width: 330px;
    height: 120px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    /* or 60px */

    text-align: center;

    /* Text colour */

    color: var(--black);
  }

  .FAQ {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1.3rem;
    /* height: 100vh; */
    width: 387px;
    margin-bottom: 12rem;
  }

  .Title h2 {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */
  }

  .Item {
    padding: 2rem 2rem 0.5rem 2rem;
    border-top: 0.5px solid var(--black);
    /* width: 957px; */
    /* height: 109px; */
    /* background: blue; */
  }
}
