:root {
  --green: #156064;
  --black: #111111;
  --light-gray: #8c8c8c;
  --gray: #333333;
  --light-green: #faffff;
  --white: #ffffff;
}

@font-face {
  font-family: "Recoleta Alt";
  src: url(./Components/fonts/Recoleta/RecoletaAlt-Bold.ttf);
}

/* @font-face {
  font-family: "Satoshi";
  src: url(./Components/fonts/Satoshi/Satoshi-Regular.ttf);
} */

@font-face {
  font-family: "General Sans";
  src: url(./Components/fonts/GeneralSan/Fonts/WEB/fonts/GeneralSans-Regular.ttf);
}

.App {
  background-color: var(--light-green);
  /* background: red; */
  /* font-family: "General Sans"; */
  /* height: 100vh; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 778px) {
  .opacity {
    /* opacity: 0.05; */
  }
}
