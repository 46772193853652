.loanOffer__hero {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loanOffer__head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  color: var(--light-gray);
  text-align: center;
  margin-bottom: 3rem;
}
.loanOffer__title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  text-align: center;
  color: var(--black);
  margin-bottom: 297px;
  width: 596px;
}
.loanOffer__body-1 {
  margin-bottom: 3rem;
}
.loanOffer__1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.loanOffer__1-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: #d9ffd3;
  padding: 3rem 5rem 0rem 5rem;
  width: 100%;
  max-width: 1040px;
  margin: 0rem 2rem;
}
.loanOffer__1-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.loanOffer__1-head {
  max-width: 390px;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.loanOffer__1-body {
  max-width: 410px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}

.loanOffer__1-wrapper img {
  max-width: 383px;
  width: 100%;
  height: 410px;
}

.loanOffer__body-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  margin: 0rem 2rem 12rem 2rem;
}
.loanOffer__body-2__content-1 {
  padding: 2rem 2rem 0rem 2rem;
  background: #fad3ff;
  border-radius: 24px;
  max-width: 492px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.loanOffer__body-2__content-1-head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 133%;
  max-width: 393px;
  width: 100%;
}
.loanOffer__body-2__content-1-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 24px */
  max-width: 390px;
  width: 100%;
  letter-spacing: -0.04em;
}
.loanOffer__body-2__content-1 img {
  max-width: 383px;
  width: 100%;
  height: 350px;
}

.loanOffer__body-2__content-2 {
  padding: 2rem 2rem 0rem 2rem;
  background: #ffd5d3;

  border-radius: 24px;
  width: 492px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.loanOffer__body-2__content-2-head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 133%;
  max-width: 393px;
  width: 100%;
}
.loanOffer__body-2__content-2-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  max-width: 390px;
  width: 100%;
  letter-spacing: -0.04em;
}
.loanOffer__body-2__content-2 img {
  max-width: 383px;
  width: 100%;
  height: 350px;
}
.loanoffer-button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  width: 140px;
  height: 55px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 778px) {
  .loanOffer__title {
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    color: var(--black);
    margin-bottom: 6rem;
    max-width: 269px;
    width: 100%;
  }
  /* .loanOffer__1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  } */

  .loanOffer__1-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    border-radius: 24px;
    gap: 0rem;
    background: #d9ffd3;
    padding: 3.5rem 0rem 0rem 0rem;
    max-width: 366px;
    width: 100%;
  }
  .loanOffer__1-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding-left: 1.5rem;
    /* align-items: center; */
    height: 200px;
  }

  .loanOffer__1-head {
    max-width: 265px;
    width: 100%;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    /* or 48px */

    letter-spacing: -0.04em;

    /* Text colour */

    color: var(--black);
  }

  .loanOffer__1-body {
    max-width: 305px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    letter-spacing: -0.04em;

    /* Text colour */

    color: var(--black);
  }
  .mobile-loanoffer-button__container {
    position: relative;
    top: 15rem;
    padding: 17px 25px;
    width: 140px;
    height: 55px;
    background: var(--green);
    border-radius: 30px;
    border: none;
    cursor: pointer;
  }

  .loanOffer__1-wrapper img {
    max-width: 275px;
    width: 100%;
    height: 300px;
  }
  .loanOffer__1-wrapper-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0rem 1rem;
  }

  .loanOffer__body-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.5rem;
    margin-bottom: 32rem;
    align-items: center;
  }

  .loanOffer__body-2__content-1 {
    padding: 1.5rem 1rem 0rem 1rem;
    /* background: #fad3ff; */
    border-radius: 24px;
    max-width: 366px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
  }

  .loanOffer__body-2__content-1-head {
    max-width: 366px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    padding: 0rem 0.5rem;
    color: var(--black);
  }

  -body {
    width: 348px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.04em;
    color: var(--black);
  }

  .loanOffer__body-2__content-1 img {
    max-width: 346px;
    width: 100%;
    height: 305px;
    margin: 0rem 1rem;
  }

  .loanOffer__body-2__content-2 {
    padding: 2rem 1rem 0rem 1rem;
    background: #ffd5d3;
    border-radius: 24px;
    max-width: 366px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }

  .loanOffer__body-2__content-2-head {
    max-width: 364px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    color: var(--black);
  }

  .loanOffer__body-2__content-2-body {
    max-width: 348px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.04em;
    color: var(--black);
  }

  .loanOffer__body-2__content-2 img {
    max-width: 366px;
    width: 100%;
    height: 310px;
  }
}
