* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.footer-1-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 26rem;
  padding: 0rem 5rem;
}
.footer__wrapper {
  max-width: 1040.13px;
  width: 100%;
}
.ft-logo {
  width: 61px;
}
.ft-logo img {
  width: 61px;
}
.footer-1 {
  display: flex;
  flex-direction: row;
  max-width: 1040.13px;
  width: 100%;
}
.footer-1-content {
  display: flex;
  flex-direction: row;

  /* gap: 15rem; */
  width: 1040px;
  justify-content: space-between;
  padding-bottom: 5.625rem;
  border-bottom: 1px solid var(--black);
}
.products h4 {
  margin-bottom: 1.5rem;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: var(--black);
  cursor: pointer;
}
.products h5 {
  margin-bottom: 0.5rem;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: var(--black);
  cursor: pointer;
}
.resources h4 {
  color: var(--black);
  margin-bottom: 1.5rem;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  cursor: pointer;
}
.resources h5 {
  color: var(--black);
  margin-bottom: 0.5rem;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
}
.footer__company h4 {
  color: var(--black);
  margin-bottom: 1.5rem;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  cursor: pointer;
}
.footer__company h5 {
  color: var(--black);
  margin-bottom: 0.5rem;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
}
.divider {
  align-items: center;
  display: flex;
  /* text-align: center; */
  justify-content: center;
}
.divider > hr {
  display: none;
  margin-top: 5rem;
  width: 1040.13px;
  border: 1px solid var(--black);
}

.footer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 57px;
  width: 100%;
}
.footer2__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1040px;
  margin: 0rem 5rem;
}

.footer2-text {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.all_right {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* Text colour */

  color: var(--black);
}
.under_line {
  text-decoration-line: underline;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* Text colour */

  color: var(--black);
}
.icon {
  height: 18px;
  width: 18px;
}
.footer-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5rem;
  gap: 1rem;
}
.foot-note {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
  width: 100%;
}
.foot-note__wrapper {
  max-width: 1040px;
  width: 100%;
  margin: 0rem 5rem;
  /* height: 21px; */
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.footer__arrowdown {
  display: none;
}
.footer2-text2 {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  /* margin-top: 1.5rem; */
}
.footer__links {
  /* width: 100px; */
}
@media screen and (max-width: 778px) {
  .footer-1-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 5rem;
    /* background: red; */
    /* width: 396px; */
  }
  .footer-1-content {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 0rem;
    /* margin-bottom: 5rem; */
    /* background: blue; */
    border-bottom: none;
    padding: 0rem;
    margin: 0rem;
  }
  .footer__arrowdown {
    display: block;
  }

  .footer__link-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .ft-logo {
    width: 36.67px;
  }

  .ft-logo img {
    width: 36.67px;
  }
  .footer__link-head h4 {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    /* Text/var(--gray) */
    margin: 0rem;
    padding: 0rem;
    color: var(--gray);
  }
  .footer__wrapper {
    width: 366px;
    /* background: yellow; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* border-bottom: 1px solid red; */
  }

  .footer-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 150px;
    /* background: brown; */
  }

  .footer__links-mobile {
    display: block;
    /* background-color: var(--light-green); */
    /* background: green; */
    position: absolute;
    right: 0;
    width: 100%;
    height: 240px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    gap: 1rem;
    margin: 0rem;
    padding: 0rem 1rem;
    /* background: red; */
    /* border-bottom: 1px solid black; */
  }

  .footer__links {
    display: none;
  }

  .divider .line {
    margin: 0rem 1rem;
    display: block;
    width: 100%;
    height: 0px;
    border-bottom: 1px solid var(--black);
  }

  .footer2 {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100px; */
    margin-bottom: 6rem;

    /* margin-top: 5rem; */
    /* height: 10rem; */
  }
  .footer2__wrapper {
    width: 100%;
    display: block;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;

    margin-top: 5rem;
    /* margin-bottom: 5rem; */
  }

  .footer2-text {
    display: block;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    gap: 0rem;

    padding-left: 1rem;
  }
  .footer-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    gap: 1rem;
    margin-top: 1.7rem;
  }

  .footer2-text2 {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .foot-note {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    /* text-align: center; */
  }
  .foot-note__wrapper {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    width: 100%;
    color: var(--black);
    margin: 0rem 1rem;
  }
}
