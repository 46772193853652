.LendingPartners__head {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.LendingPartners__head-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 131px;
  text-align: center;
  width: 1190px;
  height: 262px;
  /* Text colour */
  color: var(--black);
}
.lendingPartners__grid {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.LendingPartners__head-body {
  margin-top: 3rem;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  width: 567px;
  text-align: center;

  /* Text/var(--gray) */

  color: var(--gray);
}

@media screen and (max-width: 778px) {
  .LendingPartners__head {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .LendingPartners__head-title {
    width: 490px;
    height: 108px;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;

    /* Text colour */

    color: var(--black);
  }
  .LendingPartners__head-body {
    margin-top: 1rem;
    width: 373px;
    height: 71px;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* or 200% */

    text-align: center;

    /* Text/var(--gray) */

    color: var(--gray);
  }

  .lendingPartners__grid {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
