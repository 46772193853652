.terms {
  display: flex;
  justify-content: center;
  margin-top: 15rem;
  align-items: center;
}
.terms-container {
  width: 1040px;
}
.terms-title {
  height: 131px;

  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  /* identical to box height */
  text-align: center;
}

.terms-subtitle {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  text-align: center;
  height: 71px;
  margin-bottom: 10rem;
  /* Text/var(--gray) */
  color: var(--gray);
}
.terms-contents {
  margin-bottom: 3rem;

  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: #000000;
}
@media screen and (max-width: 778px) {
  .terms-container {
    width: 396px;
  }
  .terms-title {
    width: 348px;
    height: 54px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;

    /* Text colour */

    color: var(--black);
  }

  .terms-subtitle {
    width: 373px;
    height: 48px;
    margin-bottom: 3rem;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;

    /* Text/var(--gray) */

    color: var(--gray);
  }
  .terms-contents {
    margin-bottom: 3rem;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */

    color: #000000;
  }
}
