.calculator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}
.calculator-container {
  width: 1040px;
}
.calculator-title {
  height: 131px;

  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  /* identical to box height */

  text-align: center;
  margin-bottom: 2rem;
  /* Text colour */

  color: var(--black);
}
.calculator-subtitle {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  text-align: center;

  /* Text/var(--gray) */

  color: var(--gray);
  margin-bottom: 10rem;
}

.calculator-form {
  background: var(--white);
  border-radius: 4px;
  width: 1040px;
  height: 540px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 6rem;
}

.calculator-form-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5rem;
  /* width: 856px;
  height: 379px; */
  margin-bottom: 5rem;
}
.calculator-form-input {
  width: 359px;
  height: 79px;
  display: flex;
  flex-direction: column;
}
.calculator-form-input label {
  height: 19px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  /* Text/var(--gray) */

  color: var(--gray);
  margin-bottom: 0.5rem;
  margin-left: 0.1rem;
}
.calculator-form-input input {
  width: 359px;
  border: none;
  padding: 2rem;
  font-size: 1rem;
  font-family: "General Sans";
  background: #f5f5f5;
  border-radius: 8px;
}

.calculator-content-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  text-align: center;

  /* Primary */

  color: var(--green);
}
.calculator-content-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  /* Text/var(--gray) */
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: var(--gray);
}
.calculator-content-footer {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  width: 1040px;
  height: 240px;
  color: #000000;
}

.calculator-btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  width: 140px;
  height: 55px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 778px) {
  .calculator-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .calculator-title {
    width: 306px;
    height: 54px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;

    /* Text colour */

    color: var(--black);
  }
  .calculator-subtitle {
    width: 322px;
    height: 64px;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* or 200% */

    text-align: center;

    /* Text/var(--gray) */

    color: var(--gray);
    margin-bottom: 10rem;
  }

  .calculator-form {
    background: var(--white);
    border-radius: 4px;
    width: 396px;
    height: 696px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding-top: 6rem;
  }
  .calculator-form-container {
    display: grid;
    grid-template-columns: auto;
    gap: 3rem;
    /* width: 856px;
  height: 379px; */
    margin-bottom: 5rem;
  }
  .calculator-form-input {
    width: 359px;
    height: 79px;
    display: flex;
    flex-direction: column;
  }
  .calculator-form-input label {
    width: 191px;
    height: 19px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: left;

    /* Text/var(--gray) */

    color: var(--gray);
    margin-bottom: 0.5rem;
    margin-left: 0.1rem;
  }
  .calculator-form-input input {
    width: 359px;
    height: 56px;
    border: none;
    /* Gray / gray-3 */

    background: #f5f5f5;
    border-radius: 8px;
  }

  .calculator-content-title {
    width: 107px;
    height: 33px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    text-align: center;

    color: var(--green);
  }

  .calculator-content-body {
    width: 396px;
    height: 576px;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */

    /* Text/var(--gray) */

    color: var(--gray);
    margin-top: 3rem;
    margin-bottom: 3rem;
    color: var(--gray);
  }
  .calculator-content-footer {
    width: 396px;
    height: 352px;
    left: 16px;
    top: 1831px;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */

    color: #000000;
  }
}
