.PartnersIntegration__hero {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PartnersIntegration__head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  color: var(--light-gray);
  text-align: center;
  margin-bottom: 3rem;
}
.PartnersIntegration__title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  text-align: center;
  color: var(--black);
  width: 517px;
  margin-bottom: 7rem;
}
.PartnersIntegration__body-1 {
  margin-bottom: 3rem;
}

.PartnersIntegration__1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
}
.PartnersIntegration__1-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: #fff4d3;

  padding: 3rem 5rem 0rem 5rem;
}
.PartnersIntegration__1-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.PartnersIntegration__1-head {
  width: 390px;
  height: 120px;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.partners_button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
  width: 140px;
  height: 55px;
}
.PartnersIntegration__1-body {
  width: 410px;
  height: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.PartnersIntegration__1-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 30px;
  gap: 10px;
  /* position: absolute; */
  width: 140px;
  height: 55px;
  left: 52px;
  top: 348px;
  /* Primary Colour */
  background: var(--green);
  border-radius: 30px;
}
.PartnersIntegration__1-button p {
  width: 80px;
  height: 21px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  /* Gray / gray-1 */
  color: var(--white);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.PartnersIntegration__1-wrapper img {
  width: 383px;
  height: 410px;
}

.PartnersIntegration__2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
}
.PartnersIntegration__2-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: white;

  padding: 3rem 5rem 0rem 5rem;
}
.PartnersIntegration__2-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.PartnersIntegration__2-head {
  width: 390px;
  height: 120px;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.PartnersIntegration__2-body {
  width: 410px;
  height: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.PartnersIntegration__2-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 30px;
  gap: 10px;
  /* position: absolute; */
  width: 140px;
  height: 55px;
  left: 52px;
  top: 348px;
  /* Primary Colour */
  background: var(--green);
  border-radius: 30px;
}
.PartnersIntegration__2-button p {
  width: 80px;
  height: 21px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  /* Gray / gray-1 */
  color: var(--white);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.PartnersIntegration__2-wrapper img {
  width: 383px;
  height: 410px;
}

.PartnersIntegration__3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
}
.PartnersIntegration__3-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: #f7eaff;

  padding: 3rem 5rem 0rem 5rem;
}
.PartnersIntegration__3-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.PartnersIntegration__3-head {
  width: 390px;
  height: 120px;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.PartnersIntegration__3-body {
  width: 410px;
  height: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.PartnersIntegration__3-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 30px;
  gap: 10px;
  /* position: absolute; */
  width: 140px;
  height: 55px;
  left: 52px;
  top: 348px;
  /* Primary Colour */
  background: var(--green);
  border-radius: 30px;
}
.PartnersIntegration__3-button p {
  width: 80px;
  height: 21px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  /* Gray / gray-1 */
  color: var(--white);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.PartnersIntegration__3-wrapper img {
  width: 383px;
  height: 410px;
}

.PartnersIntegration__4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
}
.PartnersIntegration__4-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: #24262d;

  padding: 3rem 5rem 0rem 5rem;
}
.PartnersIntegration__4-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.PartnersIntegration__4-head {
  width: 390px;
  height: 120px;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: white;
}
.PartnersIntegration__4-body {
  width: 410px;
  height: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: white;
}
.PartnersIntegration__4-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 30px;
  gap: 10px;
  /* position: absolute; */
  width: 140px;
  height: 55px;
  left: 52px;
  top: 348px;
  /* Primary Colour */
  background: var(--green);
  border-radius: 30px;
}
.PartnersIntegration__4-button p {
  width: 80px;
  height: 21px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  /* Gray / gray-1 */
  color: var(--white);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.PartnersIntegration__4-wrapper img {
  width: 383px;
  height: 410px;
}

.PartnersIntegration__5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15rem;
}
.PartnersIntegration__5-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: white;

  padding: 3rem 5rem 0rem 5rem;
}
.PartnersIntegration__5-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.PartnersIntegration__5-head {
  width: 390px;
  height: 120px;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.PartnersIntegration__5-body {
  width: 410px;
  height: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.PartnersIntegration__5-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 30px;
  gap: 10px;
  /* position: absolute; */
  width: 140px;
  height: 55px;
  left: 52px;
  top: 348px;
  /* Primary Colour */
  background: var(--green);
  border-radius: 30px;
}
.PartnersIntegration__5-button p {
  width: 80px;
  height: 21px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  /* Gray / gray-1 */
  color: var(--white);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.PartnersIntegration__5-wrapper img {
  width: 383px;
  height: 410px;
}
