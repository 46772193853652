.download__button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/* .download__button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 30px;
  gap: 16px;
  width: 195px;
  height: 66px;
  border-radius: 30px;
} */

.download__button-icon {
  font-size: 24px;
}
/* .download__button-text {
  width: 100px;
  height: 32px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 117%;
  text-align: left;
} */

@media screen and (max-width: 778px) {
  /* .download__button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 30px;
    gap: 16px;

    width: 173px;
    height: 44px;



    background: var(--black);
    border-radius: 30px;
  } */

  /* .download__button-text {
    width: 73px;
    height: 28px;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 117%;
  
  } */
}
