* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.download {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 6.25rem 5rem;
}

.download__overlay {
  max-width: 1042px;
  width: 100%;
  height: 380px;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(17, 17, 17, 0.77),
    rgba(17, 17, 17, 0.77)
  );
  border-radius: 20px;
  z-index: 10;
}
.download-container {
  background: #156064;
  width: 1042px;
  height: 380px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.download__image {
  height: 800px;
  width: 800px;
  position: absolute;
  top: 0px;
  background-image: url(../Home/Images/liquid18.png);
  background-repeat: no-repeat;
  background-position: cover;
  background-size: 800px 800px;
  /* left: 400px; */
}

.download-container-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  position: relative;
  color: white;
  margin-top: 6rem;
  /* bottom: 200px; */
  /* width: 100%; */
  /* left: -20px; */
  z-index: 10;
}
.download-button {
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  z-index: 10;
}
.download__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2.5rem;
  position: absolute;
}

.download__button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17px 30px;
  gap: 16px;
  width: 195px;
  height: 66px;
  border-radius: 30px;
}
.download__button-text {
  width: 100px;
  height: 32px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 117%;
  text-align: left;
}

@media screen and (max-width: 778px) {
  .download-container {
    background: #156064;
    /* width: 1042px; */
    height: 422px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* text-align: center; */
    /* align-content: center; */
  }
  .mobile-download__overlay {
    /* width: 1042px; */
    height: 422px;
    border-radius: 50px;
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(17, 17, 17, 0.77),
      rgba(17, 17, 17, 0.77)
    );

    z-index: 10;
  }
  .download__image {
    height: 550px;
    width: 656px;
    position: absolute;
    top: 0px;
    background-image: url(../Home/Images/liquid18.png);
    background-repeat: no-repeat;
    background-position: cover;
    background-size: 656px 550px;
    left: -65px;
  }
  .download {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 4.4rem 1rem;
    border-radius: 50px;
    overflow: hidden;
  }
  .download-container-title {
    height: 72px;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }

  .download-container-title p {
    width: 229px;
  }

  .mobile__download-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    position: absolute;
  }
  .download-button {
    height: 164px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
}
