* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
}

.navbar__image {
  width: 61px;
  height: 65px;
  padding: 0rem;
}
.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  width: 100%;
}

.nav-link {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  color: var(--black);
}
.nav-link_item > li {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}
.nav-link_item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.mobile-menu-icon {
  display: none;
}

.button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}
.mobile_navbar__image {
  width: 23.13px;
  height: 29px;
}
