.contactUs__wrapper {
  margin-top: 7rem;
  padding-bottom: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactUs__head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--light-gray);
  margin-bottom: 3rem;
}
.contactUs__title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  text-align: center;
  color: var(--black);
  width: 637px;
  margin-bottom: 12rem;
}
.contactUs__form {
  display: flex;
  flex-direction: column;
  /* width: 25%; */
  padding: 3rem;
  border-radius: 24px;
  font-family: "General Sans";

  background-color: white;
}
.contactUs__form input {
  padding: 0.5rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: none;
  width: 466px;
  height: 56px;
  margin-bottom: 3rem;
  font-family: "General Sans";
}
.contactUs__form label {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  width: 100px;
  height: 19px;
  margin-bottom: 0.25rem;
}
.contactUs__btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactus-button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  width: 140px;
  height: 55px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}
.textarea {
  height: 175px;
  width: 466px;
  resize: none;
  padding: 20px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
  margin-bottom: 5rem;
  font-family: "General Sans";
}

.form-notification {
  width: 100%;
  padding: 0px 10px;
  margin: 10px 0px;
}

.form-notification__wrapper {
  max-width: 769px;
  width: 100%;
  display: flex;
  color: #fff;
  font-family: "General Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 18px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #156064;
  margin: auto;
}
@media screen and (max-width: 778px) {
  .contactUs__title {
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    color: var(--black);
    max-width: 300px;
    width: 100%;
    margin-bottom: 4rem;
  }

  .contactUs__form {
    display: flex;
    flex-direction: column;
    width: 75%;
    padding: 2rem;
    border-radius: 12px;
    background-color: white;
  }

  .contactUs__form input {
    padding: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 8px;
    border: none;
    width: 100%;
    margin-bottom: 3rem;
    font-family: "General Sans";
  }

  .contactUs__form label {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    width: 100px;
    height: 19px;
    margin-bottom: 0.25rem;
  }
  .textarea {
    width: 100%;
    height: 175px;
    background: #f5f5f5;
    border-radius: 8px;
    resize: none;
    margin-bottom: 4rem;
    padding: 1.5rem;
  }
}
