.scoreRate__hero {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.scoreRate__head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  color: var(--light-gray);
  text-align: center;
  margin-bottom: 3rem;
}

.scoreRate__title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  text-align: center;
  color: var(--black);
  width: 517px;
  margin-bottom: 7rem;
}
.scoreRate__body-1 {
  margin-bottom: 3rem;
}
.scoreRate__1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.scoreRate__1-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: black;
  padding: 3rem 2rem 0rem 2rem;
  overflow: hidden;
  position: relative;
  margin: 0rem 1rem;
}

.scorerate__liquidImage {
  position: absolute;
  height: 900px;
  width: 900px;
  left: 250px;
  top: -300px;
  z-index: 10;
}
.scoreRate__1-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  margin: 0rem 1rem;
}
.scoreRate__1-head {
  max-width: 390px;
  width: 100%;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: white;
  z-index: 100;
}
.scoreRate__1-body {
  z-index: 10;
  max-width: 410px;
  width: 100%;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: white;
}

.scorerate__iphone {
  max-width: 383px;
  width: 100%;
  /* height: 410px; */
  z-index: 10;
}

.scoreRate__body-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 12rem;
}
.scoreRate__body-2__content-1 {
  padding: 2rem 2rem 0rem 2rem;
  background: #d3fff7;
  border-radius: 24px;
  max-width: 492px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-left: 1rem;
}
.scoreRate__body-2__content-1-head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 133%;
  max-width: 393px;
  width: 100%;
  color: var(--black);
}
.scoreRate__body-2__content-1-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  max-width: 390px;
  width: 100%;
  letter-spacing: -0.04em;
  color: var(--black);
}
.scoreRate__body-2__content-1 img {
  max-width: 383px;
  width: 100%;
  height: 350px;
}

.scoreRate__body-2__content-2 {
  padding: 2rem 2rem 0rem 2rem;
  background: #d3ebff;
  border-radius: 24px;
  max-width: 492px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-right: 1rem;
}
.scoreRate__body-2__content-2-head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 133%;
  max-width: 393px;
  width: 100%;
  color: var(--black);
}
.scoreRate__body-2__content-2-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 24px */
  max-width: 390px;
  width: 100%;
  letter-spacing: -0.04em;
  color: var(--black);
}
.scoreRate__body-2__content-2 img {
  max-width: 383px;
  width: 100%;
  height: 350px;
}
.scorerate-button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  width: 140px;
  height: 55px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 778px) {
  .scoreRate__title {
    max-width: 216px;
    width: 100%;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;

    /* Text colour */

    color: var(--black);
  }

  .scoreRate__1-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 24px;
    gap: 0rem;
    background: black;
    width: 396px;
    margin: 0rem 1rem;
    align-items: center;
    padding: 3.5rem 0rem 0rem 0rem;
  }
  .scoreRate__1-head {
    max-width: 305px;
    width: 100%;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    letter-spacing: -0.04em;
    color: var(--white);
  }
  .scoreRate__1-body {
    max-width: 305px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    letter-spacing: -0.04em;

    /* Gray / gray-1 */

    color: var(--white);
  }
  .scorerate__iphone {
    max-width: 275px;
    width: 100%;
    height: 290px;
    margin: 0rem 1rem;
  }

  .scorerate-button__container {
    position: relative;
    top: 16.3rem;
    right: 0.5rem;
    padding: 17px 25px;
    width: 140px;
    height: 55px;
    background: var(--green);
    border-radius: 30px;
    border: none;
    cursor: pointer;
    z-index: 100;
  }

  .scoreRate__body-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-bottom: 29rem;
  }

  .scoreRate__body-2__content-1 {
    background: #d3fff7;
    border-radius: 24px;
    max-width: 396px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
  .scoreRate__body-2__content-1-head {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    max-width: 350px;
    width: 100%;
    color: var(--black);
  }

  .scoreRate__body-2__content-1-body {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 24px */
    max-width: 348px;
    width: 100%;
    letter-spacing: -0.04em;
    color: var(--black);
  }

  .scoreRate__body-2__content-1 img {
    max-width: 373px;
    width: 100%;
    height: 320px;
  }

  .scoreRate__body-2__content-2 {
    padding: 2.3rem 0rem 0rem 0rem;
    background: #d3ebff;
    border-radius: 24px;
    max-width: 396px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .scoreRate__body-2__content-2-head {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    max-width: 344px;
    width: 100%;
    color: var(--black);
  }

  .scoreRate__body-2__content-2-body {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 24px */
    max-width: 313px;
    width: 100%;
    letter-spacing: -0.04em;
    color: var(--black);
  }

  .scoreRate__body-2__content-2 img {
    max-width: 360px;
    width: 100%;
    height: 304px;
  }
  .scorerate__liquidImage {
    position: absolute;
    height: 1050px;
    width: 1050px;
    left: -50px;
    top: -390px;
    z-index: 10;
  }
}
