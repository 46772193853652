@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,800;1,800&family=General Sans:wght@800&family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,800;1,500&family=General Sans&family=Montserrat:wght@500&display=swap");

.Bills__hero {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Bills__head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  color: var(--light-gray);
  text-align: center;
  margin-bottom: 3rem;
}
.Bills__title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  text-align: center;
  color: var(--black);
  width: 517px;
  margin-bottom: 7rem;
}
.Bills__body-1 {
  margin-bottom: 3rem;
}
.Bills__1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Bills__1-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: #fff4d3;

  padding: 3rem 5rem 0rem 5rem;
}
.Bills__1-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.Bills__1-head {
  width: 390px;
  height: 120px;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.Bills__1-body {
  width: 410px;
  height: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}

.Bills__1-wrapper img {
  width: 383px;
  height: 410px;
}

.Bills__body-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 12rem;
}
.Bills__body-2__content-1 {
  padding: 2rem 2rem 0rem 2rem;
  background: #ffd3e4;

  border-radius: 24px;
  width: 492px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.Bills__body-2__content-1-head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 133%;
  width: 393px;
}
.Bills__body-2__content-1-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 24px */
  width: 390px;
  letter-spacing: -0.04em;
}
.Bills__body-2__content-1 img {
  width: 383px;
  height: 350px;
}

.Bills__body-2__content-2 {
  padding: 2rem 2rem 0rem 2rem;
  background: #efffd3;

  border-radius: 24px;
  width: 492px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.Bills__body-2__content-2-head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 133%;
  width: 393px;
}
.Bills__body-2__content-2-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 24px */
  width: 390px;
  letter-spacing: -0.04em;
}
.Bills__body-2__content-2 img {
  width: 383px;
  height: 350px;
}

.bill-button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  width: 140px;
  height: 55px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 778px) {
  .Bills__title {
    width: 164px;
    height: 54px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;

    /* Text colour */

    color: var(--black);
    margin-bottom: 9rem;
  }
  .Bills__1-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 24px;
    gap: 1rem;

    width: 396px;
    background: #fff4d3;
    padding: 2rem 0rem 0rem 0rem;
  }
  .Bills__1-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding-left: 1rem;
  }
  .Bills__1-head {
    width: 255px;
    height: 96px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    /* or 48px */

    letter-spacing: -0.04em;

    /* Text colour */

    color: var(--black);
  }
  .Bills__1-body {
    width: 305px;
    height: 63px;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;

    letter-spacing: -0.04em;

    /* Text colour */

    color: var(--black);
  }

  .Bills__1-wrapper img {
    width: 275px;
    height: 300px;
  }

  .Bills__body-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 12rem;
    align-items: center;
  }
  .Bills__body-2__content-1 {
    padding: 2rem 0rem 0rem 0rem;
    background: #ffd3e4;
    width: 396px;
    border-radius: 24px;
    width: 492px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .Bills__body-2__content-1-head {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 133%;
    width: 393px;
  }
  .Bills__body-2__content-1-body {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 24px */
    width: 390px;
    letter-spacing: -0.04em;
  }
  .Bills__body-2__content-1 img {
    width: 383px;
    height: 300px;
  }

  .Bills__body-2__content-2 {
    padding: 2rem 2rem 0rem 2rem;
    background: #efffd3;

    border-radius: 24px;
    width: 492px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .Bills__body-2__content-2-head {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 133%;
    width: 393px;
  }
  .Bills__body-2__content-2-body {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 24px */
    width: 390px;
    letter-spacing: -0.04em;
  }
  .Bills__body-2__content-2 img {
    width: 383px;
    height: 300px;
  }

  .mobile-bill-button__container {
    position: relative;
    top: 17rem;
    left: 1rem;
    padding: 17px 25px;
    width: 140px;
    height: 55px;
    background: var(--green);
    border-radius: 30px;
    border: none;
    cursor: pointer;
  }

  .bills-1-body-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
