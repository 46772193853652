.NewsSingle__Section-1 {
  margin-top: 12.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* padding: 100px 200px; */
}
.NewsSingle__Section-1-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 87px;
  text-align: center;
  width: 1040px;
  height: 174px;
  /* Text/var(--gray) */
  margin-bottom: 5rem;
  color: var(--gray);
}
.NewsSingle__Section-1-image {
  width: 1040px;
  height: 437px;
  background: #e5eaef;
  border-radius: 4px;
  margin-bottom: 3rem;
}
.NewsSingle__Section-1-socials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1040px;
  height: 59px;
  align-items: center;
  margin-bottom: 5rem;
}

.NewsSingle__Section-1-socials-icon {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.NewsSingle__Section-1-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  width: 1040px;
  height: 221px;
  color: #000000;
  margin-bottom: 100rem;
}
.facebook-icon {
  color: #337fff;
  width: 58px;
  height: 58px;
  font-size: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.instagram-icon {
  font-size: 30px;
  width: 58px;
  height: 58px;
  align-items: center;
  /* color: white; */
  display: flex;
  justify-content: center;
  /* background: radial-gradient(
    circle at 33% 100%,
    #fed373 4%,
    #f15245 30%,
    #d92e7f 62%,
    #9b36b7 85%,
    #515ecf
  ); */
}
.twitter-icon {
  color: #33ccff;
  font-size: 30px;
  width: 58px;
  height: 58px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.tiktok-icon {
  /* color: #69c9d0; */
  font-size: 23px;
  width: 58px;
  height: 58px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.tiktok-icon::after {
  filter: drop-shadow(5px 5px 0 #f70250) contrast(150%) brightness(110%);
  z-index: -1;
}

.linkedin-icon {
  color: #006699;
  width: 58px;
  height: 58px;
  font-size: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.NewsSingle__Section-1-body-text {
  margin-bottom: 5rem;
}

.newsletter-content-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 60px;
  width: 445px;
  height: 120px;
  /* or 94% */
}
.newsletter-content-search {
  display: flex;
  gap: 2rem;
}

.newsletter__searchbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.newsletter__searchbar > input {
  background: var(--white);
  border-radius: 30px;
  width: 496px;
  height: 55px;
  padding: 2rem;
  border: none;
  font-family: "General Sans";
}
.newsletter {
  background: var(--gray);
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  font-family: "General Sans";
}
.newsletter-content {
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  gap: 5rem;
}

.newssingle__related-articles-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  width: 278px;
  height: 60px;
}
.newsletter-content-footer {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  width: 321px;
  height: 20px;
  color: var(--white);
}
.newssingle__related-articles {
  display: flex;
  justify-content: center;
  height: 769px;
  margin-bottom: 17rem;
}
.related-articles-content {
  width: 1040px;
  margin-top: 5rem;
}
.newssingle__related-articles-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 589px;
  gap: 5rem;
}

.newssingle__related-articles-content-img {
  background: #e5eaef;
  border-radius: 4px;
  width: 290px;
  height: 330px;
}

.newssingle__related-articles-content1 {
  width: 290px;
  height: 569px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.newssingle__related-articles-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: var(--green);
  margin-bottom: 2.5rem;
}
.newssingle__related-articles-content-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  /* Text/var(--gray) */
  width: 284px;
  height: 54px;
  color: var(--gray);
}
.newssingle__related-articles-content-body {
  width: 290px;
  height: 96px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* or 200% */

  /* Text/var(--gray) */

  color: var(--gray);
}
.social__share {
  display: none;
}
.newsletter-subscribe-btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  width: 140px;
  height: 55px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 778px) {
  .NewsSingle__Section-1-title {
    width: 396px;
    height: 132px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;

    /* Text/var(--gray) */

    color: var(--gray);
    margin-bottom: 5rem;
  }

  .NewsSingle__Section-1-image {
    width: 396px;
    height: 437px;
    background: #e5eaef;
    border-radius: 4px;
    margin-bottom: 3rem;
  }

  .NewsSingle__Section-1-socials-icon {
    display: none;
    flex-direction: row;
    background-color: red;
    gap: 8px;
  }

  .NewsSingle__Section-1-socials {
    display: flex;
    flex-direction: row;
    width: 396px;
    height: 59px;
    align-items: center;
    margin-bottom: 5rem;
  }
  .social__share {
    display: block;
  }

  .NewsSingle__Section-1-body {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */
    width: 396px;
    height: 1761px;
    color: #000000;
    margin-bottom: 150rem;
  }

  .newsletter-content-title {
    width: 311px;
    height: 96px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    /* or 150% */

    text-align: center;

    color: var(--white);
  }
  .newsletter-content-search {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .newsletter__searchbar input {
    background: var(--white);
    border-radius: 30px;
    width: 359px;
    height: 55px;
    padding: 2rem;
    border: none;
  }

  .related-articles-content {
    width: 396px;
    margin-top: 5rem;

    height: 1000px;
  }
  .newssingle__related-articles-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 589px;
    gap: 3rem;

    margin-top: 30rem;
    /* margin-bottom: 480rem; */
  }
  .newssingle__related-articles-content1 {
    width: 396px;
    height: 440px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .newssingle__related-articles {
    display: flex;
    justify-content: center;
    height: 769px;
    margin-bottom: 67rem;
  }
  .newssingle__related-articles-content-img {
    background: #e5eaef;
    border-radius: 4px;
    width: 396px;
    height: 225px;
  }

  .newssingle__related-articles-content-title {
    width: 396px;
    height: 54px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;

    /* Text/var(--gray) */

    color: var(--gray);
  }
  .newssingle__related-articles-content-body {
    width: 396px;
    height: 72px;

    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* or 200% */

    /* Text/var(--gray) */

    color: var(--gray);
  }
}
