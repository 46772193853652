/* .button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  width: 140px;
  height: 55px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
} */
.button__container-text {
  width: 90px;
  height: 21px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: var(--white);
}

@media screen and (max-width: 778px) {
}
