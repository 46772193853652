.Transfer__hero {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 7rem;
}
.Transfer__head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  color: var(--light-gray);
  text-align: center;
  margin-bottom: 3rem;
}
.Transfer__title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 131px;
  text-align: center;
  color: var(--black);
  margin-bottom: 7rem;
  max-width: 780px;
  width: 100%;
}
.Transfer__body-1 {
  margin-bottom: 3rem;
}
.Transfer__1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Transfer__1-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  gap: 5rem;
  background: #fff4d3;
  padding: 3rem 2rem 0rem 2rem;
  position: relative;
  overflow: hidden;
  margin: 0rem 1rem;
}
.transfer__liquidImage {
  height: 1050px;
  width: 1050px;
  position: absolute;
  right: -280px;
  top: -300px;
  z-index: 10;
}
.Transfer__1-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.Transfer__1-head {
  max-width: 310px;
  width: 100%;
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  z-index: 100;
  /* or 60px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.Transfer__1-body {
  max-width: 410px;
  width: 100%;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  z-index: 100;
  /* or 24px */
  letter-spacing: -0.04em;
  /* Text colour */
  color: black;
}
.transfer-button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 25px;
  width: 140px;
  height: 55px;
  background: var(--green);
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

.transfer__image {
  max-width: 383px;
  width: 100%;
  height: 410px;
  z-index: 10;
}
.transfer__image-container {
  z-index: 10;
}

.Transfer__body-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 12rem;
}
.Transfer__body-2__content-1 {
  padding: 2rem 2rem 0rem 2rem;
  background: #ffd3e4;

  border-radius: 24px;
  max-width: 492px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.Transfer__body-2__content-1-head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 133%;
  max-width: 393px;
  width: 100%;
}
.Transfer__body-2__content-1-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 24px */
  max-width: 390px;
  width: 100%;
  letter-spacing: -0.04em;
}
.Transfer__body-2__content-1 img {
  max-width: 383px;
  width: 100%;
  height: 350px;
}

.Transfer__body-2__content-2 {
  padding: 2rem 2rem 0rem 2rem;
  background: #efffd3;
  border-radius: 24px;
  max-width: 492px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.Transfer__body-2__content-2-head {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 133%;
  max-width: 393px;
  width: 100%;
}
.Transfer__body-2__content-2-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 24px */
  max-width: 390px;
  width: 100%;
  letter-spacing: -0.04em;
}
.Transfer__body-2__content-2 img {
  max-width: 383px;
  width: 100%;
  height: 350px;
}
.transfer__liquidImage6 {
  display: none;
}
@media screen and (max-width: 600px) {
  .Transfer__title {
    width: 325px;
    height: 54px;

    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;

    /* Text colour */

    color: var(--black);

    margin-bottom: 7rem;
  }

  .Transfer__1-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 24px;
    background: #fff4d3;
    max-width: 396px;
    width: 100%;
  }

  .Transfer__1-head {
    max-width: 305px;
    width: 100%;
    font-family: "Recoleta Alt";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    letter-spacing: -0.04em;
    color: var(--black);
  }

  .Transfer__1-body {
    max-width: 305px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.04em;
    color: var(--black);
  }

  .transfer__image {
    width: 275px;
    height: 263px;
    margin: 0rem 1rem;
  }
  .transfer__liquidImage6 {
    display: block;
    position: absolute;
    height: 700px;
    width: 700px;
    left: 60px;
    top: -160px;
  }
  .transfer__liquidImage {
    display: none;
  }
  .transfer__image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .Transfer__1-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
  .transfer-button__container {
    position: relative;
    top: 16rem;
    left: 2rem;
    padding: 17px 25px;
    width: 140px;
    height: 55px;
    background: var(--green);
    border-radius: 30px;
    border: none;
    cursor: pointer;
    z-index: 100;
  }

  .Transfer__body-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 18rem;
    align-items: center;
  }

  .Transfer__body-2__content-1 {
    background: #ffd3e4;
    border-radius: 24px;
    max-width: 396px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin: 0rem 1rem;
  }
  .Transfer__body-2__content-1-head {
    max-width: 366px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    color: var(--black);
  }
  .Transfer__body-2__content-1-body {
    max-width: 366px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.04em;
  }

  .Transfer__body-2__content-1 img {
    max-width: 373px;
    width: 100%;
    height: 350px;
  }

  .Transfer__body-2__content-2 {
    padding: 2rem 0rem 0rem 0rem;
    background: #efffd3;
    border-radius: 24px;
    width: 396px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .Transfer__body-2__content-2-head {
    max-width: 366px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    color: var(--black);
  }
  .Transfer__body-2__content-2-body {
    max-width: 366px;
    width: 100%;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    letter-spacing: -0.04em;

    /* Text colour */

    color: var(--black);
  }
  .Transfer__body-2__content-2 img {
    max-width: 373px;
    width: 100%;
    height: 350px;
  }
}
