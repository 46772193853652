.News__grid-item {
  width: 290px;
  height: 569px;
  background: #e5eaef;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.News__grid-item-image {
  height: 60%;
}
.News__grid-item-content {
  display: flex;
  flex-direction: column;
  background-color: var(--light-green);
  padding: 0.5rem;
  height: 50%;
  gap: 1rem;
  justify-content: center;
}
.News__grid-item-content-title {
  font-family: "Recoleta Alt";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  /* Text/var(--gray) */

  color: var(--gray);
}
.News__grid-item-content-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-family: "General Sans";
}
.News__grid-item-content-body {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* or 200% */

  /* Text/var(--gray) */

  color: var(--gray);
}
.News__grid-item-content-profile-img {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  background: #e5eaef;
}
.News__grid-item-content-profile-name {
  color: #333;
  font-family: "General Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
@media screen and (max-width: 778px) {
  .News__grid-item {
    width: 396px;
    height: 440px;
    background: #e5eaef;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
}
